<!--
 * @Author: zhouyajuan
 * @Date: 2020-10-14 09:22:22
 * @LastEditTime: 2021-05-11 10:20:30
 * @LastEditors: yangliao
 * @Description: 抄表记录
 * @FilePath: /netHallOfficialAccounts/src/views/SelfMeterRead/meterReadingRecord.vue
-->
<template>
    <div class="record-page">
        <div v-if="dataInfoFlag">
            <div class="record-topContent">
                <div class="topContentLi">
                    <em>户号</em>
                    <p>{{dataInfo.userNo}}</p>
                </div>
                <div class="topContentLi van-multi-ellipsis--l3">
                    <em>地址</em>
                    <p class="van-multi-ellipsis--l3">{{dataInfo.userAddress}}</p>
                </div>
                <!-- <p class="van-multi-ellipsis--l3">{{dataInfo.address2}}</p> -->
            </div>

            <div class="record-Btncontent" >
                <ul>
                    <li v-for="(item, index) in recordList" :key="index">
                        <div class="left-info">
                            <div class="img">
                                <img :src="item.currentMeterImage" alt="" @click="getImagePreview(item.currentMeterImage)">
                            </div>
                            <div class="txt">
                                <span>本次读数 <i>{{item.currentRead}}</i></span>
                                <p>{{item.createdDate}}</p>
                            </div>
                        </div>
                        <div class="right-info">
                            <p v-if="item.thirdBillState && item.thirdBillState !== '00'">¥{{item.thirdBillAmount}}</p>
                            <van-button type="info" size="small" class="payBtn" v-if="item.thirdBillState === '01'" @click="gotoPage(item.thirdBillId)">立即缴费</van-button>
                            <van-button plain  type="info" size="small" class="payBtn" v-if="item.thirdBillState === '02'">账单详情</van-button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <no-data v-else />
    </div>
</template>

<script>
import { ImagePreview, Toast } from 'vant';
import img from '../../assets/img.png';
import NoData from '@/components/noData';

import { readRecordList } from '../../api/home';

export default {
    name: 'meterReadingRecord',
    components: {
        NoData
    },
    data() {
        return {
            dataInfo: {},
            recordList: [],
            dataInfoFlag: true,
            userNo: this.$route.query.userNo
        }
    },
    mounted() {
        this.readRecordList();
    },
    methods: {
        async readRecordList() {
            let userNo = this.userNo;
            const {status, resultData} = await readRecordList(userNo);
            if (status === 'complete') {
                if (resultData && resultData.infos.length > 0) {
                    this.dataInfo = resultData
                    this.recordList = resultData.infos
                    this.dataInfoFlag = true
                } else {
                    this.dataInfoFlag = false;
                }
            }
        },
        // 立即缴费
        gotoPage(billId, wuserNo) {
            this.$router.push({
                name: 'FeeDetails',
                path: '/QueryPay/FeeDetails',
                query: {
                    billId,
                    wuserNo: this.dataInfo.userNo
                }
            })
        },
        // 图片预览
        getImagePreview(value) {
            ImagePreview({
                images: [value],
            });
        }
    }
}
</script>

<style lang="less" scoped>
.record-page {
    .record-topContent {
        margin: 16px;
        background: #ffffff;
        padding: 16px;
        border-radius: 8px;
        div.topContentLi {
            padding-top: 16px;
            em {
                font-style: normal;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: rgba(0,0,0,0.45);
            }
            p {
                margin: 0px;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: rgba(0,0,0,0.85);
                text-align: right;
                line-height: 22px;
                // display: inline-block;
                float: right;
            }
        }
        .topContentLi:first-child {
            position: relative;
            padding: 0px;
            padding-bottom: 16px;
        }
        .topContentLi::after{
            position: absolute;
            box-sizing: border-box;
            content: ' ';
            pointer-events: none;
            right: 0px;
            bottom: 0;
            left: 0px;
            border-bottom: 1px solid #ebedf0;
            -webkit-transform: scaleY(.5);
            transform: scaleY(.5);
        }
        p {
            margin: 0px;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0,0,0,0.85);
            text-align: right;
            line-height: 22px;
            margin-left: 35px;
        }
    }
    .record-Btncontent {
        margin: 16px;
        ul {
            li {
                display: flex;
                justify-content: space-between;
                background: #ffffff;
                padding: 12px 16px;
                margin-bottom: 10px;
                border-radius: 8px;
                .left-info {
                    display: flex;
                    justify-content: space-between;
                    .img {
                        width: 64px;
                        height: 64px;
                        display: inline-block;
                        img {
                            width: 64px;
                            height: 64px;
                            border-radius: 8px;

                        }
                    }
                    .txt {
                        display: inline-block;
                        padding-left: 12px;
                        padding-top: 2px;
                        span {
                            font-family: PingFangSC-Regular;
                            font-size: 14px;
                            color: rgba(0,0,0,0.45);
                            line-height: 20px;
                            i {
                                font-style: normal;
                                font-family: PingFangSC-Regular;
                                font-size: 14px;
                                color: rgba(0,0,0,0.85);
                                line-height: 20px;
                            }
                        }
                        p {
                            margin: 0px;
                            font-family: PingFangSC-Regular;
                            font-size: 14px;
                            color: rgba(0,0,0,0.45);
                            padding-top: 16px;
                        }
                    }
                }
                .right-info {
                    p {
                        margin: 0px;
                        font-family: PingFangSC-Regular;
                        font-size: 14px;
                        color: rgba(0,0,0,0.85);
                        text-align: right;
                        padding-top: 2px;
                    }
                    .payBtn {
                        margin-top: 13px;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
}
</style>
